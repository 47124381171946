import axiosIns from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDrivers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/drivers', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDriver(ctx) { //, { id }
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/drivers')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addDriver(ctx, userData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post('/business/drivers', userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addCard(ctx, cardData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post(`/business/drivers/${cardData.profileId}/accounts`, {
                        items: cardData.cardsData
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addTransfer(ctx, transferData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post('/business/transfer/cash', transferData.transferData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchBalances(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/transactions/balances', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchFuels(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/transactions/fuels', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchGroups(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get('/business/driver-groups', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}